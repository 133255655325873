import { Brand } from "effect"

export type Route = Brand.Branded<string, "Route">
export const Route = Brand.nominal<Route>()

export const dashboard = Route("/")

export const login = Route("/login")

export const logout = Route("/logout")
